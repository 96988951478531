#SearchleGame-canvas {
    position: relative;
    background: white;
    display: inline-block;
    color: black;
    padding: 12px 20px;
    border-radius: 20px;

    .row {
        display: flex;

        .col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 48px;
            padding: 6px 0;
            .Node {
                z-index: 1;
            }
        }
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
    }
}
