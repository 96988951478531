.button-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.button {
    border-radius: 30px;
    margin: 0px 6px;
    display: flex;
    border: 2px solid #2f9c6b;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #f5f5f5;
    padding: 8px 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;

    &.primary {
        background-color: #2f9c6b;
        border-color: #2f9c6b;
    }

    &.alt {
        background-color: #532f9c;
        border-color: #532f9c;
    }

    &.danger {
        background-color: #872828;
        border-color: #872828;
    }

    &.cancel {
        background-color: #7d7d7d;
        border-color: #7d7d7d;
    }
}
