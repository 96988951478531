.SearchleGame {
    text-align: center;
    color: white;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .feedback-text {
        min-height: 32px;
        padding-bottom: 6px;
    }

    .SearchleGame-footer {
        text-align: center;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
}
