.ErrorPage {
    text-align: center;
    padding: 5rem 0;

    .ErrorPage-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
    }
}
