.loading-animation {
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        display: flex;
    }
}
