.Searchle {
    height: 100%;

    .Searchle-main {
        padding-top: 16px;
        text-align: center;
        color: white;

        h1 {
            margin: 0;
            color: white;
        }
    }
}
