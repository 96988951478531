.PrivacyTerms {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    text-align: center;

    .PrivacyTerms-main {
        display: inline-block;
        padding: 16px 2rem 4rem;
        color: white;
        text-align: left;
        max-width: 800px;
        width: 100%;

        ul {
            li {
                margin-bottom: 10px;
            }
        }
    }
}
