#SearchleGame-instructions {
    position: relative;
    background: white;
    display: inline-block;
    color: black;
    padding: 12px 20px;
    border-radius: 20px;
    max-width: 340px;
    width: 100%;

    h4 {
        margin-top: 0;
    }

    ol li {
        font-size: 12pt;
        text-align: left;
        margin-bottom: 8px;

        .sub {
            font-style: italic;
            font-size: 10pt;
            line-height: 15px;
            color: #5c5c5c;
        }

        video {
            margin-left: -34px;
        }
    }
}
