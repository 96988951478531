.Layout {
    background-color: #021e25;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
