.timer {
    margin: 10px 0;
    font-size: 18pt;

    .digits {
        color: #f5f5f5;

        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
}
