.Node {
    padding: 6px;
    .inner {
        position: relative;
        width: 36px;
        height: 36px;
        overflow: hidden;
        font-size: 18pt;
        font-weight: bold;
        line-height: 31px;

        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        &.selected {
            color: white;
            border-radius: 50%;
        }

        &.revealed {
            color: white;
            border-radius: 50%;
        }

        &.first-word {
            background-color: #a3b1bf;
            border-radius: 50%;

            &.revealed,
            &.selected {
                background-color: transparent;
            }
        }

        span {
            position: absolute;
            top: 46%;
            transform: translate(-50%, -50%);
        }
    }
}
