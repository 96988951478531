#ShareModal {
    .modal-content {
        padding-bottom: 32px;

        .time {
            color: #532f9c;
            font-weight: bold;
        }
    }
}
